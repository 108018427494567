import { FC, useEffect, useState } from "react";
import { Result, Layout } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { StyledButton } from "styles/common";
import configuration from "lib/configs/configuration";

interface ErrorScreenProps {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}

const { Content } = Layout;

const ErrorScreen: FC<ErrorScreenProps> = (props) => {
  // Development mode: increase timeout to 3000ms
  const timeout = (process.env.NODE_ENV === "production") ? 10 : 3000;

  const [seconds, setSeconds] = useState(timeout);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (seconds === 0) {
    window.location.reload();
  }

  const subtitle = (
    <>
      <p>An internal error has occurred and will be submitted to technical support for future investigation.<br />{props.error.toString()}</p>
      <p>The application will be automatically reloaded in {seconds} s.</p>
    </>
  );

  return (
    <Layout style={{ height: "100vh" }}>
      <Content style={{ width: "67%", alignSelf: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Result
          icon={<SmileOutlined />}
          title={`${configuration.app.title} needs to be reloaded`}
          subTitle={subtitle}
          style={{ marginBottom: "100px" }}
          extra={[
            <StyledButton purple key="send-report" onClick={() => props.resetError()}>Reload now</StyledButton>,
          ]}
        >
        </Result>
      </Content >
    </Layout >
  );
};

export default ErrorScreen;
