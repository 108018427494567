import * as Sentry from "@sentry/react";
import LoadingMessage from "components/blocks/LoadingMessage";

export default abstract class BaseClassLoggable {
  public readonly LogPrefix: string;
  readonly logger: { info: (msg: any, ...rest: any[]) => any; debug: (msg: any, ...rest: any[]) => any; warn: (msg: any, ...rest: any[]) => any; error: (msg: any, ...rest: any[]) => any; };

  constructor() {
    this.LogPrefix = `[${this.constructor.name}]`;
    this.logger = createLogger({ prefix: this.LogPrefix });
  }

  protected getMethodName(method: string) {
    return this.constructor.name + "." + method;
  }

  protected handleError(error: any, methodName: string, showUiMessage?: boolean, uiMessageText?: string) {
    const scope = new Sentry.Scope();
    scope.setTag("class", this.constructor.name);
    scope.setTag("method", methodName);
    Sentry.captureException(error, scope);

    if (showUiMessage) {
      LoadingMessage.error(uiMessageText);
    }

    this.logger.error(methodName, error);
  }
}

export function createLogger(options: { prefix: string; }) {
  return {
    info: (msg: any, ...rest: any) => console.info(`${options.prefix} `, msg, ...rest),
    debug: (msg: any, ...rest: any) => console.debug(`${options.prefix} `, msg, ...rest),
    warn: (msg: any, ...rest: any) => console.warn(`${options.prefix} `, msg, ...rest),
    error: (msg: any, ...rest: any) => console.error(`${options.prefix} `, msg, ...rest),
  };
}

