import * as Sentry from "@sentry/react";
import LoadingMessage from "components/blocks/LoadingMessage";

export const captureException = (e: any, showUiMessage?: boolean, uiMessageText?: string) => {
  console.warn(e);
  Sentry.captureException(e);

  if (showUiMessage) {
    LoadingMessage.error(uiMessageText);
  }
};
