import * as Sentry from "@sentry/react";

import { useAppSelector } from "app/store";
import { getUserFullName } from "lib/helpers/userUtils";

const useSentry = () => {
  const user = useAppSelector(state => state.currentUserReducer.user);
  if (user) {
    Sentry.setUser({
      id: String(user.id),
      username: getUserFullName(user),
      email: user.email,
    });
  }
};

export default useSentry;
