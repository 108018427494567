import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

import App from "./App";
import { initSentry } from "lib/init/initSentry";
import { initLogRocket } from "lib/init/initLogRocket";
import configuration from "lib/configs/configuration";
import { store } from "./app/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import ErrorScreen from "pages/ErrorScreen";
import "antd/dist/antd.css";
import reportWebVitals from "reportWebVitals";

if (configuration.app.isProduction) {
  initSentry();
  initLogRocket();
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={(errorData) => (<ErrorScreen {...errorData} />)}>
    <Provider store={store}>
      <App />
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
