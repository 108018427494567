import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/react";

import configuration from "lib/configs/configuration";

export const initLogRocket = () => {
  LogRocket.init(configuration.logRocket.appId, {
    release: configuration.app.version,
  });
  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope((scope: any) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
};
