import * as Sentry from "@sentry/react";
import { CaptureConsole, ExtraErrorData, } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/browser";

import configuration from "lib/configs/configuration";

export const initSentry = () => {
  Sentry.init({
    dsn: configuration.sentry.dsn,
    tracesSampleRate: 1.0,
    environment: configuration.app.debugEnv,
    release: configuration.app.version,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new CaptureConsole(),
      new ExtraErrorData(),
      new Sentry.Replay(),
      new BrowserTracing()
    ],
  });
};
